import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Toaster from "./views/Toast/Toast";

import { RecoilRoot } from "recoil";

import ErrorBoundary from './views/pages/page500/errorBoundary';

import {
  AuthProvider,
  WithUser,
  WithoutUser,
} from "@iamexperiences/react-auth";

import configuration from './configuration/index';
// import "bootstrap/dist/css/bootstrap.min.css"; // Styles

import { auth } from "./auth";

auth.init({
  // This causes query parameters (e.g. upn, tenant) to trigger an auto-login.
  autoLogin: true,
  tenant: configuration.tenantId
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary key={window.location.path}>
      <RecoilRoot>
        <Toaster />
        <AuthProvider auth={auth}>
          <WithUser login={true}>
            <App />
          </WithUser>
          <WithoutUser>Unauthenticated</WithoutUser>
        </AuthProvider>
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
